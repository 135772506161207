<template>
  <div>
    <section class="quick-search-hero mb-4">
      <div class="container">
        <h2>Pencarian cepat KJN untuk:</h2>
        <div class="row mt-4">
          <div class="search-bar big">

            <form class="input-group position-search left-bigger align-items-center">
              <input v-model="quickSearchVal" type="text" placeholder="Cari informasi jabatan.." class="form-control"
                name="keyword" formcontrolname="keyword" id="keyword" />
              <div class="btn-wrapper input-group-append input-group-lg">
                <button @click="doQuickSearch" class="btn btn-ternary text-white">
                  Cari
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>

    <div class="container">
      <div class="d-flex justify-content-between">
        <div class="mt-3">
          Menampilkan <strong> {{ total }} Jabatan </strong>
        </div>

        <!-- <div class="mt-3">
          <select class="form-select" v-model="limit">
            <option v-for="(limit, idx) in limitSelectOptions" :key="idx" :value="limit">{{ limit }}</option>
          </select>
        </div> -->

      </div>
      <div class="card shadow-sm border-0 mb-3">
        <div class="card-body" style="overflow: auto">
          <table class="table table-search" style="width:100%">

            <thead>
              <tr>
                <th
                  v-for="(header, idx) in tableHeader"
                  :key="idx"
                  :class="header.label === 'Kode Jabatan' ? 'border-bottom-0 fit' : 'border-bottom-0'"
                >
                  <h4 class="fw-semibold">{{ header.label }}</h4>
                </th>
              </tr>
            </thead>

            <tbody>

              <tr class="align-items-center tbody-tr"
                v-for="(tData, idx) in data"
                :key="idx"
              >
                <td
                  v-for="header in tableHeader"
                  :key="header.id"
                  :class="header.prop == 'kode_jabatan' ? 'text-center' : header.prop == 'nama_jabatan' ? '' : 'text-left' "
                >
                  <a 
                    v-if="header.prop == 'nama_jabatan'"
                    class="text-ternary text-decoration-none fw-medium"
                    @click="toDetail(tData.id)"
                  >
                    <h5 style="cursor: pointer;">{{ tData[header.prop] }}</h5>
                  </a>
                  <h5 v-else>{{ tData[header.prop] }}</h5>
                </td>
              </tr>

              <tr v-if="(data.length < 1 || data === '') && !isLoading">
                <td colspan="4" class="text-center">Tidak Ada Data</td>
              </tr>

              <tr v-show="isLoading">
                <td colspan="4">
                  <div class="d-flex justify-content-center">
                    <div class="spinner-border"></div>
                  </div>
                </td>
              </tr>

            </tbody>

          </table>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import api from './api'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import swal from 'sweetalert2'

export default {
  components: {
    Loading
  },
  data () {
    return {
      isLoading: false,
      tableHeader: [
        {
          id: 1,
          label: 'Kode Jabatan',
          prop: 'kode_jabatan'
        },
        {
          id: 2,
          label: 'Kategori Golongan Usaha',
          prop: 'sector'
        },
        {
          id: 3,
          label: 'Golongan Pokok',
          prop: 'subsector'
        },
        {
          id: 4,
          label: 'Nama Jabatan',
          prop: 'nama_jabatan'
        }
      ],
      limitSelectOptions: [25, 50],
      data: [],
      quickSearchVal: '',
      currPage: 1,
      offset: 0,
      total: 0,
      lastPage: 2,
      limit: 25,
      nextPageAddress: ''
    }
  },
  computed: {
    getFullPath () {
      return this.$route.fullPath
    }
  },
  watch : {
    getFullPath () {
      // this.getQuickSearchExpress()
      this.getQuickSearch()
    },
    limit (val) {
      console.log('limit change')
    }
  },
  async mounted () {
    const keyword = this.$route.query.keyword
    this.quickSearchVal = keyword
    // await this.getQuickSearchExpress()
    await this.getQuickSearch()
    window.addEventListener('scroll', this.handleScroll)
    // this.scroll()
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    toDetail (id) {
      // this.$router.push(`/home/detail/${id}`)
      window.open(`/home/detail/${id}`)
    },
    doQuickSearch () {
      this.$router.push(`/home/quick-search?keyword=${this.quickSearchVal}`)
    },
    async getQuickSearch () {
      if (this.isLoading) return ''
      if (this.currPage >= this.lastPage) return ''
      // if (this.currPage >= this.lastPage) return ''
      let notFirstFetch = true
      this.isLoading = true
      let param = { limit: this.limit }
      const keyword= this.quickSearchVal
      // const { data } = await api.quickSearch2(this.quickSearchVal, this.limit, page)
      // this.data = JSON.parse(JSON.stringify(this.data)).concat(JSON.parse(JSON.stringify(data)))
      
      let res = {}
      try {
        if (this.nextPageAddress !== '') {
          if (keyword) {
            res = await api.quickSearchPhpKeyword(param, keyword, this.nextPageAddress)
          } else {
            res = await api.quickSearchPhpNonKeyword(param, this.nextPageAddress)
          }
        } else {
          if (keyword) {
            res = await api.quickSearchPhpKeyword(param, keyword)
          } else {
            res = await api.quickSearchPhpNonKeyword(param)
          }
          notFirstFetch = false
          this.currPage = 1
        }

        if (res.data === '' || res.status === 204) {
          this.data = []
          return []
        } else {
          this.lastPage = res.data.last_page
          const data = res.data.data
          this.nextPageAddress = res.data.next_page_url
          this.total = res.data.total

          if (data === undefined) {
            this.isLoading = false
            return ''
          }
          
          if (notFirstFetch) {
            this.data = [ ...JSON.parse(JSON.stringify(this.data)), ...JSON.parse(JSON.stringify(data)) ]
          } else {
            this.data = JSON.parse(JSON.stringify(data))
          }
        }
      } catch (err) {
        console.log(err)
        swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Gagal melakukan pencarian cepat'
        })
      } finally {
        this.isLoading = false
      }
    },
    scroll () {
      window.onscroll = async () => {
        if (this.isLoading) return ''
        let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight === document.documentElement.offsetHeight

        if (bottomOfWindow) {
          this.currPage = this.currPage + 1
          this.offset = this.offset + this.limit
          // await this.getQuickSearchExpress()

          // php
          await this.getQuickSearch(this.currPage)
        }
      }
    },
    async handleScroll () {
      if (this.isLoading || this.currPage >= this.lastPage || this.data.length === 0 ) return ''

      const deviation = 5
      let scrollHeight = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight
      let bottomOfWindow = ((scrollHeight - deviation) <= document.documentElement.offsetHeight) && (document.documentElement.offsetHeight <= (scrollHeight + deviation))

      if (bottomOfWindow) {
        this.currPage = this.currPage + 1
        this.offset = this.offset + this.limit
        // await this.getQuickSearchExpress()

        // php
        await this.getQuickSearch(this.currPage)
      }
    },
  }
}
</script>

<style>
  @import url('../../assets/styles/quick-search.css');
  @import url('../../assets/styles/general.css');

  .table-search thead {
    background-color: #EDF2F7;
  }
</style>